export function makeLpTitle(title) {
  const siteName = "Lonely Planet";

  /* In some cases, editors have already appended the title tag, skip those */
  if (title?.includes("- Lonely Planet")) {
    return title;
  }

  return title ? `${title} - ${siteName}` : siteName;
}
