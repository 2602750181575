import { useState, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import cn from "classnames";
import { tagular } from "@cohesion/tagular";

import { useScreens } from "@utils/component";
import { EnvelopeFeature, WorldCheckFeature } from "@icons";
import { ImgixNextBackground } from "@components/imgixNextBackground";

import { Form } from "./form";

export function NewsletterWidget({
  title = "Travel inspiration delivered directly to your inbox",
  successText = "Thank you for signing up for our newsletter.",
  errorText = "There was an error processing your request, please try again later.",
  btnText = "Subscribe now",
  stackedLayout = false,
  stackedForm = false,
  topographyBackground = false,
  classNames = "", // widget classes
  imageClasses = "", // image classes for the background
  formId,
  wrapperClassNames = "",
  titleClassNames = "",
  formClassNames = "",
  backgroundImage = false,
  backgroundImageMobile = false,
}) {
  const { screen } = useScreens();
  const isSmallScreen = ["sm"].includes(screen);
  const [success, setSuccess] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const tagularPayload = useMemo(
    () => ({
      formContext: {
        formId,
        formName: "NEWSLETTER-FORM",
        formType: "NEWSLETTER",
      },
    }),
    [formId]
  );

  const topographyBackgroundProps = useMemo(
    () => ({
      overlay: "custom",
      imageClasses: cn("bg-sand-300", imageClasses),
      src: isSmallScreen
        ? backgroundImageMobile ||
          "https://lonelyplanetstatic.imgix.net/marketing/2024/newsletter/topography-background-sm.svg"
        : backgroundImage ||
          "https://lonelyplanetstatic.imgix.net/marketing/2024/newsletter/topography-background-lg.svg",
    }),
    [isSmallScreen, backgroundImage, backgroundImageMobile, imageClasses]
  );
  const Icon = success ? WorldCheckFeature : EnvelopeFeature;

  const widgetBody = (
    <div
      ref={ref}
      className={cn(
        "mx-auto pt-12 container text-center",
        stackedLayout &&
          "max-w-2xl pb-16 min-h-[492px] md:min-h-[385px] lg:min-h-[428px] lg:pb-20",
        !stackedLayout &&
          "pb-12 min-h-[478px]  md:min-h-[322px] lg:min-h-[226px] lg:flex-row lg:flex lg:items-center lg:justify-center lg:gap-4",
        classNames
      )}
    >
      <div
        className={cn(
          "rounded-full inline-block p-3 mb-2",
          topographyBackground ? "bg-sand-200" : "bg-sand-300"
        )}
      >
        <Icon className="inline-block text-4xl" />
      </div>

      <div className={cn(!stackedLayout && "lg:text-left")}>
        <p
          className={cn(
            "pb-4 text-blue text-2xl font-semibold max-w-[350px] mx-auto md:max-w-full",
            stackedLayout && "md:text-3xl lg:text-4xl",
            titleClassNames
          )}
        >
          {!success ? title : successText}
        </p>
        {success && (
          <p className={cn("text-black-400")}>
            Check your email. We can’t wait to show you our planet!
          </p>
        )}
      </div>

      {!success && (
        <div className="lg:min-w-112">
          <Form
            btnText={btnText}
            errorText={errorText}
            success={success}
            setSuccess={setSuccess}
            tagularPayload={tagularPayload}
            formClassNames={formClassNames}
            stackedForm={stackedForm}
          />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (inView) {
      tagular("formview", {
        captureContext: {
          capturePlacement: "Inline",
          captureType: "Custom Form",
          identityRequested: false,
        },
        ...tagularPayload,
      });
    }
  }, [inView, tagularPayload]);

  return (
    <section
      className={
        (cn(!topographyBackground ? "bg-sand-100" : ""), wrapperClassNames)
      }
    >
      {topographyBackground ? (
        <ImgixNextBackground {...topographyBackgroundProps}>
          {widgetBody}
        </ImgixNextBackground>
      ) : (
        widgetBody
      )}
    </section>
  );
}

NewsletterWidget.propTypes = {
  btnText: PropTypes.string,
  classNames: PropTypes.string,
  errorText: PropTypes.string,
  formId: PropTypes.string,
  stackedLayout: PropTypes.bool,
  stackedForm: PropTypes.bool,
  successText: PropTypes.string,
  title: PropTypes.string,
  topographyBackground: PropTypes.bool,
  wrapperClassNames: PropTypes.string,
  titleClassNames: PropTypes.string,
  formClassNames: PropTypes.string,
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  backgroundImageMobile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};
