import { useElemRef } from "@utils/useElemRef";

const { useState, useEffect } = require("react");

/**
 * Set `ref={setNodeRef}` on an element you want to observe.
 * As the element enters/exits the viewport `isVisible` will toggle
 * with the value of true/false.
 * @param {*} options If options isn't specified, the observer
 * uses the document's viewport as the root,
 * with no margin, and a 0% threshold
 * (meaning that even a one-pixel change is enough to trigger a callback)
 * more about intersectionObserver here:
 * https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver
 * @param {*} defaultVisibility defaults to false, this determines
 * the starting value of the visibility state.
 * @returns { isVisible, setNodeRef }
 */
export function useIntersectionObserver(
  options = {},
  defaultVisibility = false
) {
  const [isVisible, setIsVisible] = useState(defaultVisibility);
  const [nodeRef, setNodeRef] = useElemRef(node => node);

  const checkIsVisible = entries => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(checkIsVisible, {
      root: null,
      ...options,
    });
    if (nodeRef) observer.observe(nodeRef);

    return () => {
      if (nodeRef) observer.unobserve(nodeRef);
    };
  }, [nodeRef, options]);

  return {
    isVisible,
    setNodeRef,
    nodeRef,
  };
}
