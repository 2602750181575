import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Image from "next/image";

import { imgix } from "@utils/imgix";
import { ConditionalWrapper } from "@utils/conditionalWrapper";
import { useIntersectionObserver } from "@utils/useIntersectionObserver";

function imgixLoader({ src, width, quality }) {
  const loaderSrc = `${src}&q=${quality}${
    src.includes("w") ? "" : `&w=${width}`
  }`;
  return loaderSrc;
}

// define default imgix params for all imgix sources
const defaultImgixParams = { auto: "format" };
const transformedImgixParams = params =>
  Object.entries({ auto: "format", ...params })
    .map(([key, value], i) => `${i === 0 ? "?" : "&"}${key}=${value}`)
    .join("");

export function ImgixNextBackground({
  children = null,
  className = "",
  imgixParams = {},
  quality = null,
  overlay = null,
  alt = null,
  wrapperClasses = "",
  overlayClasses = "",
  priority = false,
  imageClasses = "",
  src,
  // warning: watch out for width/height props
  // sneaking through and breaking the fill prop
  ...imgRest
}) {
  const [shouldLoad, setShouldLoad] = useState(!priority);
  // lift `q` out of imgixParams - this will
  // be passed through the image loader function
  // if there is no value from the `quality` prop
  const { q, ...restImgixParams } = imgixParams;

  // check if image is using imgix path
  // then add default and additional params
  // if not pass the image source without params
  const imgixSrc = imgix(src);
  const isImgixUrl = src.includes("imgix");
  const imageSrc = isImgixUrl
    ? `${imgix(src)}${transformedImgixParams({
        ...defaultImgixParams,
        ...restImgixParams,
      })}`
    : imgixSrc;

  const { setNodeRef, isVisible } = useIntersectionObserver({
    rootMargin: "50px 0px 0px 0px",
    threshold: 0,
  });

  /**
   * TODO:
   * useIntersectionObserver doesn't seem to unobserve so hide/load was ocurring
   * every passover of the element. Manage that ourselves here in the short term.
   */
  useEffect(() => {
    if (isVisible) {
      setShouldLoad(true);
    }
  }, [isVisible]);

  return (
    <div
      className={cn(
        { "h-full": !wrapperClasses?.includes("h-") },
        wrapperClasses
      )}
      ref={setNodeRef}
    >
      {shouldLoad && (
        <div className={cn("h-full relative", className)}>
          <Image
            {...(isImgixUrl ? { loader: imgixLoader } : {})}
            className={cn(
              "absolute w-full h-full object-cover top-0 left-0 bg-black-200",
              imageClasses
            )}
            src={imageSrc}
            {...(alt ? { alt } : { alt: "" })}
            fill
            quality={quality || q || 40}
            {...(priority ? { priority } : {})}
            {...imgRest}
          />
          <ConditionalWrapper
            condition={overlay}
            // eslint-disable-next-line react/no-unstable-nested-components
            wrapper={c => (
              <div
                className={cn(
                  "w-full h-full relative",
                  {
                    "bg-gradient-to-t from-transparent-80 via-transparent-60 to-transparent":
                      overlay === "gradient",
                  },
                  {
                    "bg-gradient-to-b from-transparent-60 via-transparent":
                      overlay === "gradient-to-b",
                  },
                  {
                    "bg-transparent-60": overlay === "full",
                  },
                  {
                    "transition-all duration-300 bg-size-200v bg-transition-v-half bg-hero-v-gradient lg:group-hover:translate-x-0":
                      overlay === "full-w-hover",
                  },
                  overlayClasses
                )}
              >
                {c}
              </div>
            )}
          >
            {children}
          </ConditionalWrapper>
        </div>
      )}
    </div>
  );
}
ImgixNextBackground.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  imgixParams: PropTypes.shape({
    w: PropTypes.number,
    h: PropTypes.number,
    q: PropTypes.number,
  }),
  quality: PropTypes.string,
  overlay: PropTypes.oneOf([
    "gradient",
    "gradient-to-b",
    "full",
    "full-w-hover",
    "custom",
  ]),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  wrapperClasses: PropTypes.string,
  overlayClasses: PropTypes.string,
  priority: PropTypes.bool,
  imageClasses: PropTypes.string,
};
