import { withIcon } from "@icons/_withIcon";

export const Arrow = withIcon(
  ({ primary }) => (
    <path
      d="M18.5 8C14.5461 8 11.3409 4.86599 11.3409 1M18.5 8C14.5461 8 11.3409 11.134 11.3409 15M18.5 8H1"
      stroke={primary}
      strokeWidth="1.5"
      strokeLinecap="square"
      fill="none"
    />
  ),
  { viewBox: "0 0 19 16" }
);
