import PropTypes from "prop-types";
import cn from "classnames";
import { Loading as LoadingIcon } from "@icons";

function Loading({ className = "" }) {
  return (
    <LoadingIcon
      className={cn("animate-spin", className, {
        "text-blue": !className.includes("text-"),
      })}
      width="64"
      height="64"
    />
  );
}

Loading.propTypes = {
  className: PropTypes.string,
};

export { Loading };
