import PropTypes from "prop-types";
import { useState } from "react";
import { SHA256, enc } from "crypto-js";
import cn from "classnames";

import { Input } from "@components/fields/input";
import { Button } from "@components/button";
import { tagular } from "@cohesion/tagular";

export function Form({
  btnText,
  errorText,
  success,
  setSuccess,
  tagularPayload,
  formClassNames = "",
  stackedForm = false,
}) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [hasFocused, setHasFocused] = useState(false);

  const handleFocus = () => {
    if (!hasFocused) {
      tagular("formstart", tagularPayload);
      setHasFocused(true);
    }
  };

  const handleChange = e => {
    setEmail(e.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const hashedEmail =
      email !== "" ? SHA256(email.toLowerCase()).toString(enc.Hex) : null;

    // Required data for Newsletter API
    const data = {
      email,
      formId: tagularPayload?.formContext?.formId,
      formName: tagularPayload?.formContext?.formName,
      formType: tagularPayload?.formContext?.formType,
    };

    await fetch("/api/newsletter", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(res => {
        setError(res.status !== 200);
        setSuccess(res.status === 200);
        if (res.status === 200) {
          document.cookie = "newsletterSubscribed=true; max-age=31536000";
          setTimeout(() => {
            tagular("submit", {
              field: [
                {
                  fieldName: "userId",
                  fieldValue: hashedEmail,
                },
              ],
              ...tagularPayload,
            });
          }, 3000);
        }
      })
      .catch(err => {
        setError(err.message);
      });
    return true;
  };

  return (
    <div className={cn("text-center", formClassNames)}>
      {error && <p className="text-red">{errorText}</p>}
      {!success && (
        <form onSubmit={handleSubmit}>
          <Input
            required
            type="email"
            value={email}
            placeholder="Email address"
            onChange={handleChange}
            onFocus={handleFocus}
            className={cn(
              "w-full h-10 max-w-full my-4 mb-0 leading-none placeholder-black",
              !stackedForm && "mb-0 md:w-3/5 xl:w-3/5"
            )}
          />

          <Button
            type="submit"
            className={cn(
              "w-full h-10 mt-4 mb-4 ml-0 leading-none btn btn-primary",
              !stackedForm && "md:mt-0 md:ml-6 md:w-auto"
            )}
          >
            {btnText}
          </Button>

          <p className="text-xs text-center text-black-400">
            Subscribe to our newsletters and promotions.{" "}
            <span className="whitespace-nowrap">
              Read our{" "}
              <a href="/legal" className="underline text-blue">
                Privacy Policy.
              </a>
            </span>
          </p>
        </form>
      )}
    </div>
  );
}

Form.propTypes = {
  btnText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  setSuccess: PropTypes.func,
  success: PropTypes.bool,
  tagularPayload: PropTypes.shape({
    formContext: PropTypes.shape({
      formId: PropTypes.string.isRequired,
      formName: PropTypes.string.isRequired,
      formType: PropTypes.string.isRequired,
    }),
  }).isRequired,
  formClassNames: PropTypes.string,
  stackedForm: PropTypes.bool,
};
